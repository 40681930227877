import { FC } from 'react'
import clsx from 'clsx'

import style from './search-button.module.scss'

interface ISearchButton {
  isSearchInputShown?: boolean
}

const SearchButton: FC<ISearchButton> = ({ isSearchInputShown }: ISearchButton) => (
  <button className={clsx(style.search, { [style.searchIsActive]: isSearchInputShown })}>
    <svg
      id="icon_search"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.001"
      viewBox="0 0 24 24.001"
    >
      <g id="Ellipse_138" data-name="Ellipse 138" fill="#fff" stroke="#000" strokeWidth="2.2">
        <circle cx="9.454" cy="9.454" r="9.454" stroke="none" />
        <circle cx="9.454" cy="9.454" r="8.354" fill="none" className={style.searchCircle} />
      </g>
      <rect
        id="Rectangle_609"
        data-name="Rectangle 609"
        width="2.2"
        height="11.471"
        rx="1.1"
        transform="translate(14.333 15.889) rotate(-45)"
        className={style.searchPath}
      />
    </svg>
  </button>
)

export default SearchButton
