const disableDocumentScroll = () => {
  document.body.style.position = 'fixed'
  document.documentElement.style.height = '100vh'
}

const enableDocumentScroll = () => {
  document.body.style.position = ''
  document.documentElement.style.height = ''
}

export { enableDocumentScroll, disableDocumentScroll }
