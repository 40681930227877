import { useSelector } from 'react-redux'

import useNavbarActions from './useNavbarActions'
import { getNavbarHeight } from './selectors'

const useNavbarHeight = () => {
  const actions = useNavbarActions()
  const navbarHeight = useSelector(getNavbarHeight)

  return {
    navbarHeight,
    setNavbarHeight: actions.setNavbarHeight,
  }
}

export default useNavbarHeight
