import { STATIC_URL } from 'constants/data'

const imgData = {
  phoneIcon: {
    src: `${STATIC_URL}/icons/icon_phone.svg`,
    alt: 'Телефон',
    width: 16,
    height: 18,
  },
}

enum Phones {
  'MAIN' = '+7 (499) 226-18-47'
}

export {
  imgData,
  Phones,
}
