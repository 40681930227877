import { useSelector } from 'react-redux'

import useNavbarActions from './useNavbarActions'
import { getSearchText } from './selectors'

const useSearch = () => {
  const actions = useNavbarActions()
  const searchText = useSelector(getSearchText)

  return {
    searchText,
    setSearchText: actions.setSearchText,
  }
}

export default useSearch
