import { useSelector } from 'react-redux'

import useNavbarActions from './useNavbarActions'
import { getLineStatus } from './selectors'

const useLineStatus = () => {
  const actions = useNavbarActions()
  const lineStatus = useSelector(getLineStatus)

  return {
    lineStatus,
    setLineStatus: actions.setLineStatus,
    setDozatorDisabled: actions.setDozatorLine,
  }
}

export default useLineStatus
