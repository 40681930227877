import { STATIC_URL } from 'constants/data'

const arrowIconBlack = `${STATIC_URL}/filters/icon_arrow_black.svg`
const iconSearch = `${STATIC_URL}/filters/icon_search.svg`

const NavbarState = {
  NOT_OPENED: 'NOT_OPENED',
  PROFILE: 'PROFILE',
  SEARCH: 'SEARCH',
  NOTIFICATION: 'NOTIFICATION',
  HAMBURGER: 'HAMBURGER',
  MEDCOINS: 'MEDCOINS',
}

const lineHeight = '36px'
const lineHeightMobile = '44px'

export { NavbarState, arrowIconBlack, iconSearch, lineHeight, lineHeightMobile }
